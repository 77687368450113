* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  color: #666;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #4a4a4a;
  margin: 0 0 5px;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.ClickToCopy {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #1d4287;
  background: transparent;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 5px;
  border: none;
  transition: 0.3s;
}

.ClickToCopy h5 {
  margin: 0 0 0 8px;
}

.ClickToCopy.copied h5 {
  color: #1acc6c;
}

.kycWrap {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.kycInner {
  width: 100%;
  max-width: 600px;
}

.kycCard {
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.kycCardHeader {
  text-align: center;
  margin: 0 0 30px;
}

.kycCardHeader h2 {
  font-size: 34px;
  margin: 0 0 15px;
}

.kycCardHeader h3 {
  font-size: 22px;
}

.kycCardBody {
  padding: 15px;
}

.kycCard ul {
  padding: 0;
  margin: 20px 0;
  list-style: none;
}

.kycButton {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 12px 25px;
  font-size: 20px;
  border-radius: 5px;
  background: #1d4287;
  border: none;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  transition: 0.3s;
}

.kycButton:hover {
  background: #183770;
}

.kycButton:disabled {
  opacity: 0.5;
  background: #1d4287;
  cursor: not-allowed;
}

.inputError {
  color: #f00;
  font-size: 12px;
}

.kycForm label {
  display: block;
  margin: 0 0 10px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}

.kycForm input,
.kycForm textarea,
.kycForm select {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #4a4a4a;
  transition: 0.3s;
}

.kycForm input:focus,
.kycForm textarea:focus,
.kycForm select:focus {
  border: 1px solid #1d4287;
  box-shadow: 0 0 5px rgba(29, 66, 135, 0.25);
}

.formRow.has-error input,
.formRow.has-error textarea,
.formRow.has-error select {
  border: 1px solid #ff0000;
}

.formRow.has-error input:focus,
.formRow.has-error textarea:focus,
.formRow.has-error select:focus {
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.25);
}

.kycForm input:disabled,
.kycForm textarea:disabled,
.kycForm select:disabled {
  background: #f1f1f1;
  color: #999;
  cursor: not-allowed;
}

.formRow {
  margin: 0 0 30px;
}

.kycQrWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.kycSteps li {
  display: flex;
  align-items: center;
}

.kycSteps li + li {
  margin-top: 30px;
}

.kycSteps li > div {
  flex: 1;
}

.kycStepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e6e6eb;
}

.kycStepIcon img {
  max-width: 85%;
}

.kycStepBottom {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.kycStepBottomIcon {
  margin-right: 10px;
}

.qrImg {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 0 10px;
  max-width: 180px;
}

.kycVerifyInfo {
  display: flex;
  align-items: center;
}

.kycVerifyInfo p {
  margin-left: 5px;
  line-height: 0;
}

.kycIdType {
  margin: 15px 0;
}

.kycIdType button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  font-size: 1.125rem;
  border-radius: 5px;
  background: #f5f5f5;
  color: #2b2b2b;
  border: none;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e4e4e4;
  transition: 0.3s;
}

.kycIdType button svg {
  transition: all 0.3s;
}

.kycIdType button:hover {
  background: #1d4287;
  color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.kycIdType button:hover svg {
  fill: #fff;
}

.kycIdType button > span {
  margin-left: 10px;
  line-height: 0;
}

.kycIdType button + button {
  margin-top: 10px;
}

.kycBack {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
}

.kycBack h4 {
  margin: 0 0 0 10px;
  font-size: 20px;
  line-height: 0;
}

.kycCameraWrap {
  margin: 15px 0;
  position: relative;
  text-align: center;
  opacity: 0;
  height: 0;
  visibility: hidden;
  z-index: 9;
}

.has-camera .kycCameraWrap {
  opacity: 1;
  height: auto;
  visibility: visible;
}

.has-camera .kycIdType {
  display: none;
}

.kycCameraWrap > button {
  margin-top: 20px;
  max-width: 250px;
}

.kycPhotoPreview {
  display: flex;
  max-width: 480px;
  margin: 0 auto;
}

.kycPhotoPreview img {
  border-radius: 5px;
}

.kycCameraWrap .kycCamera {
  position: relative;
  width: 100%;
  padding-top: 75%;
  margin: 0 auto;
  border-radius: 5px;
  min-height: 250px;
}

.kycCameraWrap .kycCamera.has-photo {
  padding-top: 0 !important;
}

.isMobile .kycCameraWrap .kycCamera {
  padding-top: calc((4 / 3) * 100%);
}

.kycPhotoPreview,
.kycCameraWrap .kycCamera img,
.kycCameraWrap .kycCamera video {
  overflow: hidden;
}

.kycCameraWrap h3 {
  text-transform: capitalize;
  font-size: 24px;
  margin: 10px 0;
}

.kycVideo {
  position: relative;
  z-index: 1;
}

.kycCapturedImg {
  position: relative;
  z-index: 3;
}

.cameraInner {
  position: relative;
  background: #000;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
  border: 1px solid #ccc;
}

.cameraActionsTop,
.cameraActionsBottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.cameraActionsTop .kycBack h4 {
  color: inherit;
  margin-bottom: 3px;
  font-weight: 400;
}

.cameraActionsTop .kycBack {
  background: transparent;
  color: #fff;
  margin: 0;
}

.kycHelpText {
  margin: 15px 0;
}

.kycCameraWrap .cameraInner button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.85);
  transition: 0.3s;
}

.kycCameraWrap .cameraInner .kycCameraBtn {
  background: #fff;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
}

.kycCameraWrap .cameraInner .kycCameraBtn:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.75);
}

.kycCameraWrap .cameraInner button:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.kycCamera video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.kycCamera img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}

.kycCameraWrap.selfie .kycCamera::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(transparent 50%, rgba(0, 0, 0, 0.25) 0%);
  border-radius: 5px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 1;
}

.kycCameraWrap.id .kycCamera::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(95% - 12px);
  padding-top: 56.25%;
  border: 1px solid #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  z-index: 2;
}

.kycCameraWrap .kycCamera.has-photo::before,
.kycCameraWrap .kycCamera.has-photo::after {
  display: none;
}

.kycUpload > .kycButton {
  max-width: 250px;
}

/* Success Page */
.successWrap {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f5f5;
}

.successInner {
  max-width: 750px;
  min-width: 250px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 50px 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

.successInner h1 {
  font-size: 2.75rem;
  margin: 20px 0;
}

.successInner h4 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #4eae49;
}

.successWrap.errorWrap h4 {
  color: #f16867;
}

.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 9;
}

.kycStepWrap {
  position: relative;
  padding: 15px;
}

.kycStepTitle {
  max-width: 350px;
  text-align: center;
}

.kycStepTitle h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.kycStepInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.kycStepBody {
  margin: 30px 0;
}

.kycStepBody img {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.kycStepFooter {
  width: 100%;
  max-width: 280px;
}
